<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Flagging Billing Admisi MoU Satuan</h4>
          </template>
          <template v-slot:body>
            <!-- BEGIN:Search -->
            <div class="align-items-center p-4">
              <b-form>
                <b-form-group
                  class="row align-items-center"
                  label-cols-sm="1"
                  label="Masa:"
                  label-for="masa"
                >
                  <input-primary
                    id="masa"
                    v-model="search.masa"
                    type="number"
                    max-length="5"
                    required
                  />
                </b-form-group>
                <b-form-group
                  class="row align-items-center"
                  label-cols-sm="1"
                  label="NIM:"
                  label-for="nim"
                >
                  <input-primary
                    id="nim"
                    v-model="search.nim"
                    type="number"
                    max-length="9"
                    required
                  />
                </b-form-group>
                <!-- Button -->
                <b-row lg="3" class="mt-5">
                  <b-button
                    class="btn-block w-25 mx-auto"
                    variant="primary"
                    type="button"
                    @click="createData"
                    :disabled="!isValidSearch"
                    ><i class="ri-save-line"></i>Proses</b-button
                  >
                </b-row>
              </b-form>
            </div>
            <!-- ./END:Search -->
            <hr size="2" v-show="detailData" />
            <!-- BEGIN:Form -->
            <div class="align-items-center p-4" v-show="detailData">
              <b-form>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="NAC:"
                  label-for="nac"
                >
                  <input-primary
                    id="nac"
                    v-model="form.nac"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Nomor Billing:"
                  label-for="nobilling"
                >
                  <input-primary
                    id="nobilling"
                    v-model="form.nobilling"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Nama Mahasiswa:"
                  label-for="namaMahasiswa"
                >
                  <input-primary
                    id="namaMahasiswa"
                    v-model="form.namaMahasiswa"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Program Studi:"
                  label-for="programStudi"
                >
                  <input-primary
                    id="programStudi"
                    v-model="form.programStudi"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="UPBJJ:"
                  label-for="upbjj"
                >
                  <input-primary
                    id="upbjj"
                    v-model="form.upbjj"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Jenis Tagihan:"
                  label-for="jenisTagihan"
                >
                  <input-primary
                    id="jenisTagihan"
                    v-model="form.jenisTagihan"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Status Bayar:"
                  label-for="statusBayar"
                >
                  <input-primary
                    id="statusBayar"
                    v-model="form.statusBayar"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Status Billing:"
                  label-for="statusBilling"
                >
                  <input-primary
                    id="statusBilling"
                    v-model="form.statusBilling"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Masa Registrasi Awal:"
                  label-for="masaRegistrasiAwal"
                >
                  <input-primary
                    id="masaRegistrasiAwal"
                    v-model="form.masaRegistrasiAwal"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Jumlah Tagihan:"
                  label-for="jumlahTagihan"
                >
                  <input-primary
                    id="jumlahTagihan"
                    v-model="form.jumlahTagihan"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <input-datalists
                    id="statusAktif"
                    class="w-full text-sm"
                    placeholder="Pilih Status Aktif"
                    v-model="form.statusAktif"
                    label="keterangan"
                    required
                    :options="listStatusAktif || []"
                    :reduce="listStatusAktif => listStatusAktif.value"
                    :clearable="false"
                  >
                    <template #header>
                      <label class="block"
                        >Status Aktif: <span class="text-danger">*</span></label
                      >
                    </template>
                    <template v-slot:option="option">
                      <div class="flex justify-between p-1">
                        {{ option.value }} |
                        {{ option.keterangan }}
                      </div>
                    </template>
                  </input-datalists>
                </b-form-group>
                <b-form-group>
                  <label for="keteranganUbah">Keterangan:</label>
                  <input-primary
                    id="keteranganUbah"
                    v-model="form.keteranganUbah"
                    type="textarea"
                    max-length="255"
                    rows="3"
                  />
                </b-form-group>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <b-button
                      class="btn-block"
                      variant="success"
                      type="button"
                      @click="createData"
                      :disabled="!isValidForm"
                      ><i class="ri-save-line"></i>Simpan</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
              <!-- ./END:Form -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'kerjasama.ubahStatusBillingSatuan',
  computed: {
    ...mapState('authService', ['token']),
    isValidSearch() {
      const status = this.search.masa && this.search.nim != null
      return status
    },
    isValidForm() {
      const status = this.form.statusAktif != null
      return status
    }
  },
  async mounted() {
    /** Mounted code here... */
  },
  methods: {
    /**
     * Search Data
     */
    async searchData() {
      /** Search Data */
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/flaggingAdmisi/cariData?nobilling=${this.search.nac}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataFlagging = res.data
          this.form = { ...this.listDataFlagging }
          /** Program Studi */
          this.form.programStudi = this.listDataFlagging.kodeProgramStudi
            ? this.listDataFlagging.kodeProgramStudi +
              ' | ' +
              this.listDataFlagging.namaProgramStudi
            : null
          /** UPBJJ */
          this.form.upbjj = this.listDataFlagging.kodeUpbjj
            ? this.listDataFlagging.kodeUpbjj +
              ' | ' +
              this.listDataFlagging.namaUpbjj
            : null
          /** Jenis Tagihan */
          this.form.jenisTagihan = this.listDataFlagging.kodeJenisBayar
            ? this.listDataFlagging.kodeJenisBayar +
              ' | ' +
              this.listDataFlagging.keterangan
            : null
          /** Status Bayar */
          this.form.statusBayar = this.listDataFlagging.statusBank
            ? this.listDataFlagging.statusBank +
              ' | ' +
              this.listDataFlagging.keteranganBank
            : null
          /** Status Billing */
          this.form.statusBilling = this.listDataFlagging.kodeStatusBilling
            ? this.listDataFlagging.kodeStatusBilling +
              ' | ' +
              this.listDataFlagging.keteranganBilling
            : null
          /** Status Aktif */
          this.form.statusAktif = '1'
          this.detailData = true
        })
        .catch(err => {
          console.log('ERROR', err.response)
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
    },
    /**
     * Create Data
     */
    async createData() {
      const bodyFormData = new FormData()
      bodyFormData.append('masa ', this.search.masa)
      bodyFormData.append('nim ', this.search.nim)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/flaggingAdmisi/prosesFlaggingMou`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err.response)
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
    }
  },
  data() {
    return {
      detailData: false,
      // Search
      search: {
        masa: null,
        nim: null
      },
      // Form
      form: {
        /** set data */
        nac: null,
        namaMahasiswa: null,
        masaRegistrasiAwal: null,
        jumlahTagihan: null,
        keteranganUbah: null,
        /** custom set data */
        programStudi: null,
        upbjj: null,
        jenisTagihan: null,
        statusBayar: null,
        statusBilling: null,
        statusAktif: null,
        /** data post */
        nobilling: null,
        idStatusTerbayar: null,
        kodeJenisBayar: null,
        idStatusBilling: null
      },
      // Data List
      listDataFlagging: [],
      listStatusAktif: [
        {
          id: 1,
          value: '1',
          keterangan: 'Aktif'
        },
        {
          id: 2,
          value: '0',
          keterangan: 'Tidak Aktif'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (
      MENU_ROLE.KERJASAMA_FLAGGING_BILLING_SATUAN.includes(String(idUnitKs))
    ) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
    // if (MENU_ROLE.IN_DEVELOPMENT.includes(idUnitKs)) {
    //   return next()
    // } else {
    //   Vue.$toast.error('Anda tidak memiliki hak akses')
    //   return next({ name: 'sidebar.dashboard' })
    // }
  }
}
</script>
